import { Container, Grid } from "@mui/material";
import BlogCard2 from "~/components/blog-cards/BlogCard2";
import { H3 } from "~/components/Typography";
import useWindowSize from "~/hooks/useWindowSize";
import { FC, useEffect, useState } from "react";
import { Post } from "~/models";
import Carousel from "~/components/carousel/Carousel";

// ======================================================================
type BlogProps = { posts: Post[] };
// ======================================================================

const Blog: FC<BlogProps> = ({ posts }) => {
  const width = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(3);
  useEffect(() => {
    if (width < 650) setVisibleSlides(1);
    else if (width < 1024) setVisibleSlides(2);
    else setVisibleSlides(3);
  }, [width]);

  return (
    <Container sx={{ mb: { xs: 2, md: 4 }, mt: { xs: 1, md: 4 } }}>
      <H3
        fontFamily={"Rubik"}
        sx={{ fontSize: { md: "24px", xs: "20px" }, mb: { xs: 1, md: 4 } }}
        fontWeight="600"
      >
        Зөвлөгөө
      </H3>
      <Carousel
        totalSlides={posts?.length ? posts?.length : 3}
        visibleSlides={visibleSlides}
        infinite={true}
        autoPlay
        showArrow={true}
      >
        {(posts || []).map((item, index) => (
          <BlogCard2 post={item} key={`blog-post-${index}`} />
        ))}
      </Carousel>
    </Container>
  );
};

export default Blog;
