import { Box, styled } from "@mui/material";
import HoverBox from "~/components/HoverBox";
import BazaarImage from "~/components/BazaarImage";
import { H4 } from "~/components/Typography";
import React from "react";
import BazaarCard from "../BazaarCard";

export const StyledBazaarCard = styled(BazaarCard)(() => ({
  maxHeight: "255px",
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "10px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  padding: "1rem",
  zIndex: 2,
  backgroundColor: "white",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));
export const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  display: "inline-block",
  [theme.breakpoints.down("sm")]: { display: "block" },
  // "&:hover": {
  //   "& .controller": { right: 1 },
  //   "& .cart-controller": { top: 272, opacity: 1 }
  // },
}));
// ==========================================================
type ProductCard5Props = {
  name: string;
  image: string;
};
// ==========================================================

const ProductCard5: React.FC<ProductCard5Props> = ({ image, name }) => {
  return (
    // <Box>
    //   <HoverBox borderRadius="5px" mb={1}>
    //     <BazaarImage
    //       alt={name}
    //       width={"100%"}
    //       height={"100%"}
    //       src={image}
    //       sx={{ mx: "auto", px: 1.5 }}
    //     />
    //   </HoverBox>
    //   <H4 fontSize={14}>{name}</H4>
    // </Box>
    // <StyledBazaarCard>
    <BazaarImage
      alt={name}
      width={"100%"}
      height="100%"
      sx={{ maxHeight: "240px" }}
      src={image}
      className="nomin-rounded-image"
      // sx={{ mx: "auto", px: 1.5, pt: "3px" }}
    />
    // </StyledBazaarCard>
  );
};

export default ProductCard5;
