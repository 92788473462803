import { Box, Container, Grid } from "@mui/material";
import BazaarCard from "~/components/BazaarCard";
import Carousel from "~/components/carousel/Carousel";
import CategorySectionHeader from "~/components/CategorySectionHeader";
import DottedStar from "~/components/icons/DottedStar";
import RankBadge from "~/components/icons/RankBadge";
import BestsellerProductCard from "~/components/product-cards/BestsellerProductCard";
import BestsellerCategoryCard from "~/components/product-cards/BestsellerCategoryCard";
import { Link } from "@remix-run/react";
import { FC, useState } from "react";

// ==========================================================
type Props = {
  topRatedList: any[];
  booksCategories: any;
  parentLink: string;
  parentLinkCategory: string;
};
// ==========================================================

const Bestseller: FC<Props> = (props) => {
  const { booksCategories, topRatedList, parentLink, parentLinkCategory } =
    props;
  const [visibleSlides, setVisibleSlides] = useState(3);
  let count = 0;
  let newCategories = booksCategories
    .filter((item) => item.image != "")
    .slice(0, 6);
  const books = topRatedList.slice(0, 9);
  return (
    <Box sx={{ mb: { xs: 2, md: 4 } }}>
      <Container>
        <Grid container columnSpacing={5}>
          <Grid item lg={6} xs={12} sx={{ mb: { xs: 3, md: 0 } }}>
            <CategorySectionHeader
              title="Бестселлер"
              seeMoreLink={parentLink}
            />
            <Grid container spacing={3} height={"100%"}>
              {books.map((item, ind) => (
                <Grid item xs={4} key={ind}>
                  <Link to={item.url}>
                    <BestsellerProductCard product={item} ind={ind} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <CategorySectionHeader
              title="Номын ангилал"
              seeMoreLink={parentLinkCategory}
            />{" "}
            {/* ene deer carousel ashiglana */}
            <Grid container spacing={3} height={"100%"}>
              {newCategories.map((item, ind) => (
                <Grid item xs={6} key={ind}>
                  <Link
                    to={item.url}
                    style={{
                      display: "flex",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    <BestsellerCategoryCard {...item} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Bestseller;
