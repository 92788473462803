import { Box, Container, Grid, styled } from "@mui/material";
import BazaarCard from "~/components/BazaarCard";
import CategorySectionHeader from "~/components/CategorySectionHeader";
import Category from "~/components/icons/Category";
import BazaarImage from "~/components/BazaarImage";
import { Link } from "@remix-run/react";
import { FC, useEffect, useState } from "react";
import { CDN_MEDIA } from "~/utils/constants";
import useWindowSize from "~/hooks/useWindowSize";
import Carousel from "~/components/carousel/Carousel";

const StyledBazaarCard = styled(BazaarCard)(({ theme }) => ({
  display: "flex",
  // borderRadius: 28,
  alignItems: "center",
  transition: "all 250ms ease-in-out",
  boxShadow: "2px 2px 2px 1px #d3627e",

  backgroundColor: "#F6F9FC",
  position: "relative",
  maxWidth: "100%",
  "& .image-hover": {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "contain",
    opacity: 0,
    transition: "opacity .2s",
  },
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      boxShadow: "3px 3px 2px 1px #d3627e",
      "& .image-hover": {
        opacity: 1,
      },
    },
  },
}));

interface Props {
  categories: any[];
}
const SpecialCategories: FC<Props> = ({ categories }) => {
  const width = useWindowSize();
  // const [windowWidth, setWindowWidth] = useState(false);
  // useEffect(() => {
  //   window.innerWidth <= 1024 ? setWindowWidth(true) : setWindowWidth(false);
  // }, []);
  const [visibleSlides, setVisibleSlides] = useState(3);

  useEffect(() => {
    if (width <= 600) setVisibleSlides(3);
    else if (width <= 1024) setVisibleSlides(3);
    else setVisibleSlides(6);
  }, [width]);

  return (
    <Container sx={{ mb: { xs: 1.5, md: 4 } }}>
      <CategorySectionHeader title="Ангилал" />

      {/* <div style={{ width: "100%",}}>
  <Box className="scrollPhone"
    component="div"
    sx={{
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
      my: 2,
      borderRadius: 2,
      fontWeight: '600',
      display:"flex",
      loop: 'true',
    }}
  >

  {categories.map((item, ind) => (
    <Box key={ind} sx={{padding:0}}>
      <Link to={`${item.url}`}>
        <StyledBazaarCard sx={{ marginRight: "10px",}}>
          <Box sx={{ py: 1, fontSize: "11px", display: "flex", p:0}}>
            <BazaarImage marginRight={"5px"} src={item.image}
            sx={{width:{xs:"110px", md:"100%"}}}
            ></BazaarImage>
          </Box>
        </StyledBazaarCard>
      </Link>
    </Box>
  ))}
  </Box>
</div> */}

      <Carousel
        showArrow={false}
        totalSlides={categories.length}
        visibleSlides={visibleSlides}
        infinite={true}
        autoPlay={true}
        sx={{ height: "100%" }}
      >
        {categories.map((item, index) => (
          <Link to={`${item.url}`} key={index}>
            <StyledBazaarCard elevation={1} sx={{ mb: 0.5 }}>
              <BazaarImage
                width={"100%"}
                className="image-main"
                alt="cat"
                sx={{ borderRadius: "10px" }}
                src={item.image}
              />
              <BazaarImage
                width={"100%"}
                className="image-hover"
                alt="cat"
                sx={{ borderRadius: "10px" }}
                src={item.secondaryImage}
              />
            </StyledBazaarCard>
          </Link>
        ))}
      </Carousel>
    </Container>
  );
};

export default SpecialCategories;

{
  /* <Grid container spacing={3}>
{categories.map((item, ind) => (
  <Grid item sm={2} xs={4} key={ind}>
    <Link to={`${item.url}`}>
      <StyledBazaarCard elevation={1}>
        <BazaarImage width={"100%"} alt="cat" src={item.image} />
      </StyledBazaarCard>
    </Link>
  </Grid>
))}
</Grid> */
}
