import { Box, BoxProps, styled } from "@mui/material";
import BazaarImage from "~/components/BazaarImage";
import { FC } from "react";
import { Link } from "@remix-run/react";

// custom styled components
const CardWrapper = styled(Box)(() => ({
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
}));

const CardContent = styled(Box)(() => ({
  top: 0,
  left: 32,
  zIndex: 1,
  height: "100%",
  display: "flex",
  position: "absolute",
  flexDirection: "column",
  justifyContent: "center",
}));

// ========================================================
type SmallBannerCardProps = {
  img: string;
  url?: string;
};
// ========================================================

const SmallBannerCard: FC<SmallBannerCardProps & BoxProps> = ({
  img,
  url = "",
  ...props
}) => {
  return (
    <Link to={url}>
      <CardWrapper {...props}>
        <BazaarImage
          alt="category"
          height="100%"
          width="100%"
          src={img}
          className="nomin-rounded-image"
          sx={{
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />
        <CardContent />
      </CardWrapper>
    </Link>
  );
};

export default SmallBannerCard;
