import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { Link } from "@remix-run/react";
import useWindowSize from "~/hooks/useWindowSize";

import { useState } from "react";

export default function MobilePopup() {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <img
        src="/assets/images/logos/Nomin-logo-white.svg"
        width="110px"
        style={{ position: "absolute", left: "10px" }}
      />
      <Button
        sx={{
          color: "white",
          fontWeight: "600",
          fontSize: "14px",
          // ":hover":{color:"#333"}
        }}
        size="small"
        onClick={handleClose}
      >
        Одоо биш
      </Button>
      <Link to={"https://onelink.to/4h4fek"}>
        <Button
          sx={{
            color: "#2d68c4",
            background: "white",
            fontWeight: "600",
            fontSize: "14px",
            borderRadius: "20px",
            padding: "1px 12px",
            zIndex: "1",
            ":hover": {
              background: "white",
              boxShadow: "1px 1px 3px 1px #bbbbbb",
            },
          }}
          size="small"
          onClick={handleClose}
        >
          Апп нээх
        </Button>
      </Link>
    </React.Fragment>
  );
  const [windowWidth, setWindowWidth] = useState(false);
  React.useEffect(() => {
    window.innerWidth <= 1024 ? setWindowWidth(true) : setWindowWidth(false);
  }, []);

  return windowWidth ? (
    <>
      <Button onClick={handleClick} sx={{ display: "none" }}>
        Open simple snackbar
      </Button>
      <Snackbar
        open={open}
        // autoHideDuration={30000}
        autoHideDuration={null}
        onClose={handleClose}
        // message="Note archived"
        action={action}
        color="info"
        ContentProps={{
          sx: {
            background: "#2d68c4",
            marginBottom: "60px",
            py: "10px",
            zIndex: "100",
          },
        }}
        sx={{ zIndex: "100" }}
      />
    </>
  ) : null;
}
