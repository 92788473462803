import { Box, styled } from "@mui/material";
import HoverBox from "~/components/HoverBox";
import BazaarImage from "~/components/BazaarImage";
import { H4 } from "~/components/Typography";
import React from "react";
import { Product } from "~/models";
import { formatCurrency } from "~/utils/utils";
import BazaarCard from "../BazaarCard";
import badge from "public/assets/images/badges/bookmark.svg";

export const StyledBazaarCard = styled(BazaarCard)(() => ({
  height: "100%",
  margin: "auto",
  display: "flex",
  overflow: "visible",
  borderRadius: "10px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  padding: "1rem",
  paddingBottom: "calc(1rem - 5px)",
  zIndex: 2,
  backgroundColor: "white",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));
export const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  display: "inline-block",
  [theme.breakpoints.down("sm")]: { display: "block" },
  // "&:hover": {
  //   "& .controller": { right: 1 },
  //   "& .cart-controller": { top: 272, opacity: 1 }
  // },
}));
export interface BestsellerProductCardProps {
  product: Product;
  ind: number;
}

const BestsellerProductCard: React.FC<BestsellerProductCardProps> = ({
  product,
  ind,
}) => {
  return (
    <StyledBazaarCard>
      <Box sx={{ position: "absolute", right: "11px", zIndex: 2, top: "-8px" }}>
        <img src={badge} width={30} height={30} />
        <Box
          sx={{
            position: "absolute",
            top: "5px",
            right: "13px",
            color: "white",
            fontWeight: 600,
          }}
        >
          {ind + 1}
        </Box>
      </Box>
      <ImageWrapper>
        <BazaarImage
          src={product.defaultImage}
          height="100%"
          width="100%"
          alt={product.title}
          sx={{ mx: "auto", px: 1.5, pt: 0.5 }}
        />
      </ImageWrapper>

      <ContentWrapper>
        <H4
          fontSize={14}
          fontWeight="600"
          textAlign="center"
          mb={0.5}
          title={product.title}
          ellipsis="true"
        >
          {product.title}
        </H4>
        <H4
          fontSize={14}
          fontWeight="600"
          textAlign="center"
          color="primary.main"
        >
          {formatCurrency(product.price)}
        </H4>
      </ContentWrapper>
    </StyledBazaarCard>
  );
};

export default BestsellerProductCard;
