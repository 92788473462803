import { H3, Span } from "~/components/Typography";
import React, { FC } from "react";

// component props interface
interface CountBoxProps {
  digit: string;
  title: string;
}

const CountBox: FC<CountBoxProps> = ({ digit, title }) => {
  return (
    <H3 color={"#0bfa46"} sx={{width:{md:"65px", xs:"40px"}, borderRadius:"8px", background:"white", textAlign:"center", fontSize:{md:"40px", xs:"24px"}}} >
      {digit}{" "}
      {/* <Span color="#0bfa46" fontSize="14px" fontWeight="600">
        {title}
      </Span> */}
    </H3>
  );
};

export default CountBox;

