import { Box, Card, styled } from "@mui/material";
import { Link } from "@remix-run/react";
import { FC } from "react";
import Image from "~/components/BazaarImage";
import { FlexBox } from "~/components/flex-box";
import { H4, Paragraph, Span } from "~/components/Typography";
import { Post } from "~/models";

// custom styled components
const ImageBox = styled(Box)(() => ({
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
  "& img": { transition: "0.3s" },
  ":hover": { "& img": { transform: "scale(1.1)" } },
}));

// =====================================================
type BlogCard2Props = {
  post: Post;
};
// =====================================================

const BlogCard2: FC<BlogCard2Props> = ({ post }) => {
  return (
    <Card sx={{ borderRadius: 2 }}>
      <Link to={`${post.url}`}>
        <ImageBox maxHeight={240}>
          <Image width={"100%"} height={230} src={post.img} alt="blog-1" />
        </ImageBox>
      </Link>
      <Box px={2} pt={1} pb={3}>
        <Link to={`${post.url}`}>
          <H4
            fontWeight={600}
            lineHeight={"25px"}
            height={"40px"}
            fontSize="15px"
            textAlign="center"
            marginTop="10px"
            marginBottom={"10px"}
          >
            {post.title}
          </H4>
        </Link>

        <Paragraph
          mb={3}
          lineHeight={"20px"}
          fontWeight={400}
          height={"60px"}
          overflow="hidden"
          textOverflow={"ellipsis"}
          whiteSpace="nowrap"
        >
          {post.description}
        </Paragraph>
        <FlexBox justifyContent={"flex-end"}>
          <Link to={`${post.url}`}>
            <Span
              sx={{
                gap: 1,
                lineHeight: 1,
                // fontWeight: 600,
                alignItems: "center",
                fontWeight:"700",
                position: "relative",
                paddingBottom: "4px",
                display: "inline-flex",
                ":after": {
                  left: 0,
                  bottom: 0,
                  width: "0%",
                  content: "''",
                  height: "2px",
                  transition: "0.3s",
                  position: "absolute",
                },
                ":hover": {
                  color: "primary.main",
                  "&::after": {
                    width: "100%",
                    backgroundColor: "primary.main",
                  },
                },
              }}
            >
              Цааш унших
            </Span>
          </Link>
        </FlexBox>
      </Box>
    </Card>
  );
};

export default BlogCard2;
