import { FlexBox } from "~/components/flex-box";
import React, { FC, useCallback, useEffect, useState } from "react";
import CountBox from "./CountBox";
import { Span } from "~/components/Typography";

// component props interface
interface CountDownProps {
  expireDate: any;
}

const initialState = {
  // days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const Countdown: FC<CountDownProps> = ({ expireDate }) => {
  const [timeLeft, setTimeLeft] = useState(initialState);
  
  const calculateTimeLeft = useCallback(() => {
    const distance = expireDate - new Date().getTime();
    //  
    // if date expire
    if (distance < 0) return initialState;
    
    return {
      // days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
    };
  }, [expireDate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);


  
    // const timeLeft1 = ` "0" +${timeLeft.hours}`;
  
  return (
    <FlexBox
      sx={{
        display: "flex",
        width: {md:"25%", xs:"40%"},
        justifyContent: "space-between",
        height: "auto",
        // color:"red",
        position:"absolute",
        top:{md:60, xs:20},
        left:{md:115, xs:20},
      }}
    >
      {/* <CountBox digit={timeLeft.days} title="ӨДӨР" /> */}
      <CountBox digit={("0"+timeLeft.hours).slice(-2)} title="ЦАГ" />
      <Span sx={{color:"white", fontSize:{md:"36px", xs:"24px"}, fontWeight:"700"}}>:</Span>
      <CountBox digit={("0"+timeLeft.minutes).slice(-2)} title="МИН" />
      <Span sx={{color:"white", fontSize:{md:"36px", xs:"24px"}, fontWeight:"700"}}>:</Span>
      <CountBox digit={("0"+timeLeft.seconds).slice(-2)} title="СЕК" />
    </FlexBox>
  );
};

export default Countdown;
