import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Box, Link } from "@mui/material";
import { FC } from "react";

type SchoolPopupProps = {
  bgImage: string;
  url? : string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SchoolPopup:FC<SchoolPopupProps> = ({
 bgImage,
 url = "",
}) => {
  const [open, setOpen] = React.useState(true);
  // const [visibleSlides, setVisibleSlides] = useState(5);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
     
    <Stack spacing={2} sx={{ width: "100%"}}>
      <Snackbar open={open} autoHideDuration={8000} onClose={handleClose} anchorOrigin={{vertical:"bottom", horizontal:"left",}}>
        <Alert
          onClose={handleClose}
          icon={false}
          severity="success"
          // href="#"
          sx={{ width: "400px", height: "150px", backgroundImage:`url(${bgImage})`, backgroundColor:"transparent", display:{xs:"none", md:"flex"}}}
        >
          <Link href={url}>
            <Box width="320px" height="120px">
            </Box>
          </Link>
        </Alert>
      </Snackbar>
      
    </Stack>

  );
}

export default SchoolPopup;