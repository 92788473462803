import { Box, Container } from "@mui/material";
import BazaarImage from "~/components/BazaarImage";
import Carousel from "~/components/carousel/Carousel";
import { FlexRowCenter } from "~/components/flex-box";
import useWindowSize from "~/hooks/useWindowSize";
import { useEffect, useState } from "react";
import CategorySectionHeader from "~/components/CategorySectionHeader";
import { Link } from "@remix-run/react";

const brands = [
  {
    image: "/assets/images/brands/acer.png",
    link: "/t/s?brand=77",
  },
  {
    image: "/assets/images/brands/apple.png",
    link: "/t/s?brand=78",
  },
  {
    image: "/assets/images/brands/ascreation.png",
    link: "/t/s?brand=8853",
  },
  {
    image: "/assets/images/brands/avent.png",
    link: "/t/s?brand=2355",
  },
  {
    image: "/assets/images/brands/bahlsen.png",
    link: "/t/s?brand=14371",
  },
  {
    image: "/assets/images/brands/berlingerhaus.png",
    link: "/t/s?brand=9836",
  },
  {
    image: "/assets/images/brands/byphasse.png",
    link: "/t/s?brand=16236",
  },
  {
    image: "/assets/images/brands/calliope.png",
    link: "/t/s?brand=12365",
  },
  {
    image: "/assets/images/brands/casio.png",
    link: "/t/s?brand=3138",
  },
  {
    image: "/assets/images/brands/corania.png",
    link: "/t/s?brand=16183",
  },
  {
    image: "/assets/images/brands/englishhome.png",
    link: "/t/s?brand=12578",
  },
  {
    image: "/assets/images/brands/flormar.png",
    link: "/t/s?brand=8918",
  },
  {
    image: "/assets/images/brands/garnier.png",
    link: "/t/s?brand=8902",
  },
  {
    image: "/assets/images/brands/happycall.png",
    link: "/t/s?brand=3040",
  },
  {
    image: "/assets/images/brands/hp.png",
    link: "/t/s?brand=90",
  },
  {
    image: "/assets/images/brands/ingco.png",
    link: "/t/s?brand=10083",
  },
  {
    image: "/assets/images/brands/josephjoseph.png",
    link: "/t/s?brand=14363",
  },
  {
    image: "/assets/images/brands/keramamarazzi.png",
    link: "/t/s?brand=8855",
  },
  {
    image: "/assets/images/brands/lg.png",
    link: "/t/s?brand=93",
  },
  {
    image: "/assets/images/brands/loreal.png",
    link: "/t/s?brand=11600",
  },
  {
    image: "/assets/images/brands/luminarc.png",
    link: "/t/s?brand=9147",
  },
  {
    image: "/assets/images/brands/morphyrichards.png",
    link: "/t/s?brand=10617",
  },
  {
    image: "/assets/images/brands/nachtmann.png",
    link: "/t/s?brand=9012",
  },
  {
    image: "/assets/images/brands/nyx.png",
    link: "/t/s?brand=12566",
  },
  {
    image: "/assets/images/brands/philips.png",
    link: "/t/s?brand=100",
  },
  {
    image: "/assets/images/brands/promate.png",
    link: "/t/s?brand=5573",
  },
  {
    image: "/assets/images/brands/russellhobbs.png",
    link: "/t/s?brand=12400",
  },
  {
    image: "/assets/images/brands/samsung.png",
    link: "/t/s?brand=104",
  },
  {
    image: "/assets/images/brands/stanley.png",
    link: "/t/s?brand=10506",
  },
  {
    image: "/assets/images/brands/terranova.png",
    link: "/t/s?brand=11291",
  },
  {
    image: "/assets/images/brands/thenorthface.png",
    link: "/t/s?brand=8836",
  },
  {
    image: "/assets/images/brands/viviangray.png",
    link: "/t/s?brand=16162",
  },
  {
    image: "/assets/images/brands/wakemake.png",
    link: "/t/s?brand=16166",
  },
  {
    image: "/assets/images/brands/wilmax.png",
    link: "/t/s?brand=9029",
  },
];

const BrandsSection = () => {
  const width = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(5);

  useEffect(() => {
    if (width < 650) setVisibleSlides(2);
    else if (width < 800) setVisibleSlides(3);
    else if (width < 1024) setVisibleSlides(4);
    else setVisibleSlides(5);
  }, [width]);

  return (
    <Container sx={{ mb: { xs: 2, md: 4 } }}>
      {/* <CategorySectionHeader title="Брэнд" seeMoreLink={"/brands"} /> */}
      <CategorySectionHeader title="Брэнд" />
      <Box bgcolor="white">
        <Carousel
          autoPlay={true}
          infinite={true}
          showArrow={false}
          totalSlides={brands.length}
          visibleSlides={visibleSlides}
          sx={{ ":hover": { cursor: "pointer" } }}
        >
          {brands.map((item, index) => (
            <FlexRowCenter
              // maxWidth={110}
              height="100%"
              margin="auto"
              key={`brand-section-${index}`}
            >
              <Link to={item.link}>
                <BazaarImage src={item.image} alt="brand" width="100%" />
              </Link>
            </FlexRowCenter>
          ))}
        </Carousel>
      </Box>
    </Container>
  );
};

export default BrandsSection;
