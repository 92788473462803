import { Box } from "@mui/material";
import BazaarCard from "~/components/BazaarCard";
import Carousel from "~/components/carousel/Carousel";
import CategorySectionCreator from "~/components/CategorySectionCreator";
import { FlexBox } from "~/components/flex-box";
import HoverBox from "~/components/HoverBox";
import AlarmClock from "~/components/icons/AlarmClock";
import BazaarImage from "~/components/BazaarImage";
import { H4 } from "~/components/Typography";
import useWindowSize from "~/hooks/useWindowSize";
import { Link } from "@remix-run/react";
import { FC, useEffect, useState } from "react";

type RecentlyViewedProps = {
  data: any;
};

const RecentlyViewed: FC<RecentlyViewedProps> = ({ data }) => {
  const width = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(5);

  useEffect(() => {
    if (width < 370) setVisibleSlides(1);
    else if (width < 650) setVisibleSlides(2);
    else if (width < 950) setVisibleSlides(4);
    else setVisibleSlides(5);
  }, [width]);

  return (
    <CategorySectionCreator title="Сүүлд үзсэн бараа">
      <Box my={2}>
        <Carousel
          totalSlides={data.length}
          visibleSlides={visibleSlides}
          showArrow={data.length > visibleSlides}
        >
          {data.map((item) => (
            <Box py={0.5} key={`recently-viewed-product-${item.id}`}>
              <BazaarCard sx={{ p: "1rem" }}>
                <Link to={item.url}>
                  <BazaarImage
                    width={100}
                    height={100}
                    src={item.image}
                    alt={item.title}
                    style={{ margin: "auto" }}
                  />
                  <H4
                    fontWeight="600"
                    fontSize="14px"
                    mt={1}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.title}
                  </H4>
                </Link>
              </BazaarCard>
            </Box>
          ))}
        </Carousel>
      </Box>
    </CategorySectionCreator>
  );
};

export default RecentlyViewed;
