import { Card, Container, Grid, List, ListItem, styled } from "@mui/material";
import Carousel from "~/components/carousel/Carousel";
import { carouselStyled } from "~/components/carousel/CarouselStyled";
import NavLink2 from "~/components/nav-link/NavLink2";
import ProductCard from "~/components/product-cards/ProductCard";
import { H3 } from "~/components/Typography";
import useWindowSize from "~/hooks/useWindowSize";
import { FC, useEffect, useState } from "react";
import { Link } from "@remix-run/react";
import CategorySectionCreator from "~/components/CategorySectionCreator";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  cursor: "pointer",
  padding: "10px 0 0 0",
  transition: "all 0.3s",
  ":hover": { color: theme.palette.primary.main },
}));

// ======================================================================
type HomeProductSectionProps = {
  title: string;
  products: any[];
  subcategories: any[];
  parentLink: string;
};
// ======================================================================

const HomeProductSection: FC<HomeProductSectionProps> = ({
  title,
  products,
  subcategories,
  parentLink,
}) => {
  const width = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(3);

  useEffect(() => {
    if (width < 500) setVisibleSlides(2);
    else if (width < 650) setVisibleSlides(2);
    else if (width < 950) setVisibleSlides(3);
    else if (width < 1025) setVisibleSlides(4);
    else setVisibleSlides(3);
  }, [width]);

  return width < 1025 ? (
    <CategorySectionCreator
      title={title}
      seeMoreLink={parentLink}
      sx={{ mb: { xs: 2, md: 4 } }}
    >
      <Carousel
        totalSlides={products.length}
        visibleSlides={visibleSlides}
        infinite={true}
        autoPlay={true}
        sx={{ height: "100%" }}
      >
        {products.map((product, ind) => (
          <ProductCard product={product} key={ind} />
        ))}
      </Carousel>
    </CategorySectionCreator>
  ) : (
    <Container sx={{ mb: { xs: 2, md: 4 } }}>
      <Grid container columnSpacing={3}>
        <Grid item md={3} xs={12} sx={{ mb: { xs: 2, md: 0 } }}>
          <Card
            elevation={0}
            sx={{
              px: 4,
              height: "100%",
              py: 2,
              borderRadius: "8px",
              border: 0,
            }}
          >
            <H3 fontWeight="700">{title}</H3>
            <List sx={{ mb: 2, fontWeight: "500" }}>
              {subcategories.slice(0, 7).map((subcategory, ind) => (
                <StyledListItem key={ind}>
                  <Link to={subcategory.url}>{subcategory.name}</Link>
                </StyledListItem>
              ))}
            </List>

            <NavLink2
              href={parentLink}
              text="Бүгдийг үзэх"
              color="dark.main"
              hoverColor="dark.main"
            />
          </Card>
        </Grid>

        <Grid item md={9} xs={12}>
          <Carousel
            totalSlides={products.length}
            visibleSlides={visibleSlides}
            infinite={true}
            autoPlay={true}
            sx={{ height: "100%" }}
          >
            {products.map((product, ind) => (
              <ProductCard product={product} key={ind} />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeProductSection;
