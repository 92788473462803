/* eslint-disable react/no-unescaped-entities */
import { Box, styled } from "@mui/material";
import { Link } from "@remix-run/react";
import React, { FC } from "react";
import Countdown from "~/page-sections/fashion-shop/countdown";

// custom styled components
const CardWrapper = styled(Box)<{ mode: string }>(({ theme, mode }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    textAlign: "center",
    backgroundImage: "none",
  },
}));

// ===============================================================
type BigBannerCardProps = {
  bgImage: string;
  url?: string;
  mode?: "dark" | "light";
  content?: React.ReactChild;
};
// ===============================================================

const BigBannerCard: FC<BigBannerCardProps> = ({
  bgImage,
  mode = "dark",
  url = "",
  content,
}) => {
  const expireDate = new Date("2023-8-28 24:00:00").getTime();
  return (
    <Link to={url}>
      <CardWrapper
        mode={mode}
        sx={{
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <img src={bgImage} width={"100%"} style={{ borderRadius: "8px" }} />
        {/* {bgImage === "https://cdn5.nomin.mn/media/neosolax/banner/24.jpg" ? <>
        <Countdown expireDate={expireDate}/>
        </>:null } */}
      </CardWrapper>
    </Link>
  );
};

export default BigBannerCard;
