import { useLoaderData } from "@remix-run/react";
import { ClientOnly } from "remix-utils/client-only";
import MobileNavbarMenu from "~/components/navbar/MobileNavbarMenu";
import SchoolPopup from "~/components/popups/schoolPopup";
import MobilePopup from "~/components/popups/mobilePopup";
import { Category, RecentlyViewedProduct } from "~/models";
import Blog from "~/page-sections/fashion-shop/Blog";
import FashionSection from "~/page-sections/fashion-shop/FashionSection";
import Newsletter from "~/page-sections/fashion-shop/Newsletter";
import { SpecialProducts } from "~/page-sections/home";
import Bestseller from "~/page-sections/home/Bestseller";
import BrandsSection from "~/page-sections/home/BrandsSection";
import FoodsSection from "~/page-sections/home/FoodsSection";
import HomeProductSection from "~/page-sections/home/HomeProductSection";
import HotDeal from "~/page-sections/home/HotDeal";
import MainBanner from "~/page-sections/home/MainBanner";
import RecentlyViewed from "~/page-sections/home/RecentlyViewed";
import SalesBanner from "~/page-sections/home/SalesBanner";
import Services from "~/page-sections/home/Services";
import SpecialCategories from "~/page-sections/home/SpecialCategories";
import ThinBanner from "~/page-sections/home/ThinBanner";
import { loader } from "~/route-containers/home.server";
import OmniSection from "~/page-sections/home/OmniSection";
import UpointSection from "~/page-sections/home/UpointSection";

export { loader };

export default function IndexPage() {
  const {
    banners,
    specialProducts,
    productsWithGift,
    productsShoes,
    hotDeals,
    electronicsItems,
    omniItems,
    upointItems,
    homestyleItems,
    cosmeticsItems,
    bestsellerItems,
    categories,
    homeBlogs,
    // valentineProducts,
  } = useLoaderData<typeof loader>();
  const fashionBanner1 = banners.find(
    (item) => item.code === "banner_fashion_1"
  );
  const fashionBanner2 = banners.find(
    (item) => item.code === "banner_fashion_2"
  );
  const fashionBanner3 = banners.find(
    (item) => item.code === "banner_fashion_3"
  );
  const heroBanners = banners.find((item) => item.code === "banner_main_1");

  const miniBanners = banners.find((item) => item.code === "banner_mini_1")
    ? banners.find((item) => item.code === "banner_mini_1").banner[0]
    : null;

  const mainSmallBanner1 = banners.find(
    (item) => item.code === "banner_main_2"
  );
  const mainSmallBanner2 = banners.find(
    (item) => item.code === "banner_main_3"
  );
  const specialBanners = banners.find(
    (item) => item.code === "banner_special_1"
  );
  const foodsBanners = banners.find((item) => item.code === "banner_grocery_1");
  const narrowBanner = banners.find((item) => item.code === "banner_narrow_1");
  const martBanner = banners.find((item) => item.code === "mart8");
  const electronics =
    categories.length !== 0
      ? categories.find((item) => item.id === 6011)
      : null;
  const homestyle =
    categories.length !== 0
      ? categories.find((item) => item.id === 24273)
      : null;
  const cosmetics =
    categories.length !== 0
      ? categories.find((item) => item.id === 21297)
      : null;
  const foods =
    categories.length !== 0
      ? categories.find((item) => item.id === 24175)
      : null;
  const fashion =
    categories.length !== 0
      ? categories.find((item) => item.id === 19653)
      : null;
  const bestseller =
    categories.length !== 0
      ? categories.find((item) => item.id === 21871)
      : null;

  const books =
    categories.length !== 0
      ? categories.find((item) => item.id === 19487)
      : null;
  const bookCategories =
    books.children.length !== 0
      ? books.children.find((item) => item.id === 34034)
      : null;

  let specialCategories: Category[] = [];
  let recentlyViewed: RecentlyViewedProduct[] = [];

  if (typeof window !== "undefined") {
    recentlyViewed = JSON.parse(localStorage.getItem("recentlyViewed")!) || [];
  }

  categories.map((category) => {
    if (category.special) {
      specialCategories.push(category);
    }
    if (category.noc > 0) {
      category.children.map((subcategory) => {
        if (subcategory.special) {
          specialCategories.push(subcategory);
        }
        if (subcategory.noc > 0) {
          subcategory.children.map((subcategory2) => {
            if (subcategory2.special) {
              specialCategories.push(subcategory2);
            }
          });
        }
      });
    }
  });

  return (
    <>
      {/* HERO BANNER */}

      <MainBanner
        key="main-banner"
        main={heroBanners.banner}
        small1={mainSmallBanner1.banner}
        small2={mainSmallBanner2.banner}
      />

      <MobileNavbarMenu />

      <SpecialCategories categories={specialCategories} />

      {/* Top Categories */}
      {/* <NewYearAnimation banner={newYearBanner.banner} /> */}
      <SpecialProducts
        flashDeals={specialProducts}
        title={"Онцлох бараа"}
        link={"/t/22245"}
      />
      {martBanner && martBanner.banner.length !== 0 ? (
        <ThinBanner banner={martBanner.banner} />
      ) : null}
      <SpecialProducts
        flashDeals={productsWithGift}
        title={"Бэлэгтэй бараа"}
        link={"/t/29716"}
      />

      {/* <SpecialProducts
        flashDeals={valentineProducts}
        title={"Наурызын баяр"}
        link={"/t/33977"}
      /> */}

      {/* FLASH DEALS */}

      {specialBanners && <SalesBanner slides={specialBanners} />}
      {narrowBanner && narrowBanner.banner.length !== 0 ? (
        <ThinBanner banner={narrowBanner.banner} />
      ) : null}

      <HomeProductSection
        title={electronics?.name}
        products={electronicsItems}
        subcategories={electronics.children}
        parentLink={electronics.url}
      />

      <FoodsSection
        categories={foods.children}
        parentLink={foods.url}
        banners={foodsBanners.banner}
      />

      <HomeProductSection
        title={cosmetics!.name}
        products={cosmeticsItems}
        subcategories={cosmetics.children}
        parentLink={cosmetics.url}
      />

      {/* <FashionSection
        category={fashion.children}
        banner1={fashionBanner1.banner}
        banner2={fashionBanner2.banner}
        banner3={fashionBanner3.banner}
      /> */}
      <UpointSection
        flashDeals={upointItems}
        title={"U-Point"}
        link={"/t/35485"}
      />

      <SpecialProducts
        flashDeals={productsShoes}
        title={"Гутал хувцас"}
        link={"/t/19653"}
      />

      <HomeProductSection
        title={homestyle!.name}
        products={homestyleItems}
        subcategories={homestyle.children}
        parentLink={homestyle.url}
      />

      {hotDeals && hotDeals.length !== 0 && <HotDeal hotDealList={hotDeals} />}
      <OmniSection
        flashDeals={omniItems}
        title={"Powered by OMNI"}
        link={"/t/25165"}
      />

      <Bestseller
        topRatedList={bestsellerItems}
        booksCategories={bookCategories.children}
        parentLinkCategory={books.url}
        parentLink={bestseller.url}
      />

      <BrandsSection />

      <ClientOnly fallback={<></>}>
        {() =>
          recentlyViewed.length !== 0 && (
            <RecentlyViewed data={recentlyViewed} />
          )
        }
      </ClientOnly>

      <Services serviceList={serviceList} />
      <Blog posts={homeBlogs} />
      <Newsletter />
      {miniBanners && (
        <ClientOnly fallback={<></>}>
          {() => (
            <SchoolPopup
              bgImage={miniBanners.photoUrl}
              url={miniBanners.link}
            />
          )}
        </ClientOnly>
      )}
      <ClientOnly fallback={<></>}>{() => <MobilePopup />}</ClientOnly>
    </>
  );
}

const serviceList = [
  {
    id: "1",
    icon: "/assets/images/Setgel-hanamj.webp ",
    title: "Сэтгэл ханамж",
    description:
      "Худалдан авсан (3 хоногийн дотор) бараа сэтгэлд тань үл нийцвэл хүссэнээр нь ",
    description1: "СОЛЬЖ ӨГНӨ.",
  },
  {
    id: "2",
    icon: "/assets/images/Gift.webp",
    title: "Бонус Карт",
    description:
      "Сүүлийн 2 жилд хийсэн (Номин - ийн бүх салбар дэлгүүрүүдэд) худалдан авалтын дүнгээс хамааран жижиглэнгийн үнээр авсан барааны ",
    description1: "7% ХҮРТЭЛ БОНУС БОЛОН 3% UPOINT ОНООГ ОЛГОНО.",
  },
  {
    id: "3",
    icon: "/assets/images/Truck.webp",
    title: "Үнэгүй хүргэлт",
    description:
      " nomin.mn вэбсайт, гар утасны апп болон бүх дэлгүүрээс 199,000₮-с дээш худалдан авалтыг Улаанбаатар хот дотор ",
    description1: "ҮНЭГҮЙ ХҮРГЭЖ ӨГНӨ.",
  },
  {
    id: "4",
    icon: "/assets/images/Zeel.webp",
    title: "Мөнгөө буцаах",
    description:
      "Зарагдсан барааг буцаах боломжтой (Хүргэлт хийгдсэнээс хойш 24 цагийн дотор) тул хэрэглэгч таны мөнгийг ",
    description1: "100% ЭРГҮҮЛЭН ОЛГОНО.",
  },
  {
    id: "5",
    icon: "/assets/images/Hymdral.webp",
    title: "Хямд үнэ",
    description:
      "Таны авсан (Хүргэлт хийгдсэнээс хойш 3 хоногийн дотор) эсвэл авах бараа тань өөр ижил төстэй онлайн худалдааны вэбсайтад хямд байгааг анхдагч болон мэдээлснээр ",
    description1: "ҮНИЙН ЗӨРҮҮ 200%(2 ДАХИН)",
    description2: " өгнө анхдагч биш ба мэдээлэгчдэд 110% өгнө.",
  },
];
