import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SalesBannerCard1 from "~/components/banners/SalesBannerCard1";
import SalesBannerCard2 from "~/components/banners/SalesBannerCard2";
import Carousel from "~/components/carousel/Carousel";
import useWindowSize from "~/hooks/useWindowSize";

const SalesBanner = (slides: any) => {
  const width = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(3);

  useEffect(() => {
    if (width <= 600) setVisibleSlides(1);
    else if (width <= 1024) setVisibleSlides(2);
    else setVisibleSlides(4);
  }, [width]);
  return (
    <Container sx={{ mb: { xs: 2, md: 4 } }}>
      {/* hereg bolj magadgui !!!! */}
      {/* hereg bolj magadgui !!!! */}
      {/* hereg bolj magadgui !!!! */}

      {/* <Carousel
        showArrow={false}
        totalSlides={slides.slides.banner.length}
        visibleSlides={visibleSlides}
        infinite={true}
        autoPlay={true}
        sx={{ height: "100%" }}
      >
        {(slides.slides.banner || []).map((banner, index) => (
          <SalesBannerCard2
            key={index}
            url="#"
            img={banner.photoUrl}
            contentPosition="right"
          />
        ))}
      </Carousel> */}
      <Grid container spacing={2} sx={{ mt: { xs: "-16px", md: 2 } }}>
        {(slides.slides.banner || []).map((banner, index) => (
          <Grid item xs={6} sm={3} key={index}>
            <SalesBannerCard2
              key={index}
              url="#"
              img={banner.photoUrl}
              contentPosition="right"
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SalesBanner;
