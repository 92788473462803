import { Box } from "@mui/material";
import Carousel from "~/components/carousel/Carousel";
import CategorySectionCreator from "~/components/CategorySectionCreator";
import useWindowSize from "~/hooks/useWindowSize";
import { FC, useEffect, useState } from "react";
import { Product } from "~/models";
import UpointProductCard from "~/components/product-cards/UpointProductCard";

// =============================================================
type Props = { flashDeals: any[]; title: string; link: string };
// =============================================================

export default function UpointSection({ flashDeals, title, link }: Props) {
  const [visibleSlides, setVisibleSlides] = useState(4);
  const width = useWindowSize();

  useEffect(() => {
    if (window.innerWidth < 500) setVisibleSlides(2);
    else if (window.innerWidth < 650) setVisibleSlides(2);
    else if (window.innerWidth < 950) setVisibleSlides(2);
    else setVisibleSlides(4);
  }, [width]);

  return (
    <CategorySectionCreator title={title} seeMoreLink={link}>
      <Carousel
        totalSlides={flashDeals.length}
        visibleSlides={visibleSlides}
        infinite={true}
        autoPlay={true}
      >
        {flashDeals.map((item: Product, ind) => (
          <Box key={ind}>
            <UpointProductCard product={item} />
          </Box>
        ))}
      </Carousel>
    </CategorySectionCreator>
  );
}
