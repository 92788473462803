import { Box, Grid, styled, Container } from "@mui/material";
import BazaarCard from "~/components/BazaarCard";
import BazaarImage from "~/components/BazaarImage";
import { H3, H5, Tiny } from "~/components/Typography";
import { Link } from "@remix-run/react";
import CategorySectionCreator from "~/components/CategorySectionHeader";
import React, { FC, useEffect, useState } from "react";
import useWindowSize from "~/hooks/useWindowSize";
import Carousel from "~/components/carousel/Carousel";

// styled component
const StyledBazaarCard = styled(BazaarCard)(({ theme }) => ({
  gap: "1rem",
  // height: "100%",
  mb: 1.5,
  // borderRadius: "35px",
  // boxShadow: "3px 3px 6px 3px #bbbbbb",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    flexDirection: "column",
  },
  display: "flex",
  // borderRadius: 28,
  alignItems: "center",
  transition: "all 100ms ease-in-out",
  boxShadow: "2px 2px 2px 1px #F2A8BC",
  backgroundColor: "#F6F9FC",
  position: "relative",
  maxWidth: "100%",
  "& .image-hover": {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "contain",
    opacity: 0,
    transition: "opacity .2s",
  },
  [theme.breakpoints.up("md")]: {
    "&:hover": {
      boxShadow: "3px 3px 2px 1px #F2A8BC",
      "& .image-hover": {
        opacity: 1,
      },
    },
  },
}));

// ===========================================================
type Props = {
  categories: any[];
  parentLink: string;
  banners: any[];
};
// ===========================================================

const FoodsSection: FC<Props> = ({ categories = [], parentLink, banners }) => {
  const width = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(3);
  let foodCategories = banners.filter((item) => item.image != "");

  useEffect(() => {
    if (width <= 600) setVisibleSlides(3);
    else if (width <= 1024) setVisibleSlides(3);
    else setVisibleSlides(6);
  }, [width]);
  return (
    <Container sx={{ mb: { xs: 2, md: 4 } }}>
      <Box>
        <CategorySectionCreator title="Хүнс" seeMoreLink={""} />

        <Carousel
          showArrow={false}
          totalSlides={foodCategories.length}
          visibleSlides={visibleSlides}
          infinite={true}
          autoPlay={true}
          sx={{ height: "100%" }}
        >
          {foodCategories.map((item, index) => (
            <Link to={`${item.link}`} key={`food-category-${index}`}>
              <StyledBazaarCard elevation={1} sx={{ mb: 0.5 }}>
                <BazaarImage
                  width={"100%"}
                  alt="cat"
                  src={item.photoUrl}
                  sx={{ borderRadius: "10px" }}
                />
                <BazaarImage
                  width={"100%"}
                  className="image-hover"
                  alt="cat1"
                  src={item.secondaryPhotoUrl}
                  sx={{ borderRadius: "10px" }}
                />
              </StyledBazaarCard>
            </Link>
          ))}
        </Carousel>
      </Box>
    </Container>
  );
};

export default FoodsSection;

{
  /* <Grid container spacing={3}>
{banners.map((item, ind) => (
  <Grid item lg={2} xs={4} key={ind}>
    <Link to={item.link}>
      <StyledBazaarCard hoverEffect>
        <BazaarImage
          src={item.photoUrl}
          width={"100%"}
          className="nomin-rounded-image"
        />

      </StyledBazaarCard>
    </Link>
  </Grid>
))}
</Grid> */
}
