import {
  Container,
  Grid,
  Box,
  Collapse,
  IconButton,
  styled,
  IconButtonProps,
} from "@mui/material";
import BazaarCard from "~/components/BazaarCard";
import { H3, H4, Span } from "~/components/Typography";
import React, { FC, useEffect, useRef, useState } from "react";
import BazaarImage from "~/components/BazaarImage";
import Carousel from "~/components/carousel/Carousel";
import useWindowSize from "~/hooks/useWindowSize";
interface Props {
  serviceList: any[];
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  isHover: any;
  overview: any;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, isHover, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Services: FC<Props> = ({ serviceList }) => {
  const [isHover, setIsHover] = React.useState(false);
  const handleExpandClick = (item, show) => {
    setIsHover(show);
    setSelectedItemDescription(item.description);
    setSelectedItemDescription1(item.description1);
    setSelectedItemDescription2(item.description2);
  };

  const [selectedItemDescription, setSelectedItemDescription] = useState("");
  const [SelectedItemDescription1, setSelectedItemDescription1] = useState("");
  const [SelectedItemDescription2, setSelectedItemDescription2] = useState("");
  const [open, setOpen] = useState(false);
  const width = useWindowSize();
  const [visibleSlides, setVisibleSlides] = useState(5);

  useEffect(() => {
    if (width < 370) setVisibleSlides(2);
    else if (width < 650) setVisibleSlides(3);
    else if (width < 950) setVisibleSlides(4);
    else setVisibleSlides(5);
  }, [width]);

  return (
    <Container sx={{ mb: { xs: 0, md: 0 } }}>
      <H3
        fontWeight="600"
        fontFamily={"Rubik"}
        sx={{ fontSize: { md: "24px", xs: "20px" }, mb: { xs: 1, md: 4 } }}
      >
        Биднийг сонгох шалтгаан
      </H3>

      <Carousel
        totalSlides={serviceList.length}
        visibleSlides={visibleSlides}
        showArrow={serviceList.length > visibleSlides}
      >
        {serviceList.map((item, ind) => (
          <Box key={`service-${ind}`} sx={{ py: { xs: 0, md: 0.5 } }}>
            <BazaarCard
              onMouseEnter={() => handleExpandClick(item, true)}
              onMouseLeave={() => handleExpandClick(item, false)}
              hoverEffect
              sx={{
                padding: "2rem",
                display: "flex",
                borderRadius: "8px",
                alignItems: "center",
                flexDirection: "column",
                border: "none",
                ":hover": { border: "1px #2f6db8 solid" },
              }}
            >
              <Box sx={{ px: { lg: "3rem" } }}>
                <BazaarImage
                  width={"100%"}
                  src={item.icon}
                  alt={item.title}
                ></BazaarImage>
              </Box>

              <H4
                height="30px"
                fontWeight="600"
                mt={2.5}
                mb={1.25}
                textAlign="center"
                fontSize="15px"
              >
                {item.title}
              </H4>
            </BazaarCard>
          </Box>
        ))}
      </Carousel>

      <Collapse in={isHover} unmountOnExit>
        <div
          id="example-collapse-text"
          style={{
            padding: "2rem 4rem",
            background: "#ffffff",
            fontSize: "16px",
            border: "#2f6db8 solid 1px",
            borderRadius: "10px",
            marginTop: "20px",
            textAlign: "center",
            marginLeft: "0px",
            fontWeight: "400",
          }}
        >
          {selectedItemDescription}
          <Box
            style={{
              color: "#2f6db8",
              fontWeight: "500",
              background: "#d2dff1",
              width: "fit-content",
              margin: "auto ",
              padding: "0.5rem 1.5rem",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: "5px",
            }}
          >
            {SelectedItemDescription1}
          </Box>
          {SelectedItemDescription2}
        </div>
      </Collapse>
    </Container>
  );
};

export default Services;
