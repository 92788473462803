/* eslint-disable react-hooks/exhaustive-deps */
import { AddShoppingCart, Favorite, RemoveRedEye } from "@mui/icons-material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  styled,
  Tooltip,
} from "@mui/material";
import { Link, useNavigation } from "@remix-run/react";
import { FC, useCallback, useState } from "react";
import BazaarCard from "~/components/BazaarCard";
import { AddToCartForm } from "~/components/cart";
import ProductViewDialog from "~/components/products/ProductViewDialog";
import { H3, Span } from "~/components/Typography";
import { Product } from "~/models";
import { useRootLoader } from "~/utils/use-root-loader";
import { formatCurrency } from "~/utils/utils";
import BazaarImage from "../BazaarImage";
import { FlexBetween, FlexBox } from "../flex-box";
// import { AddToWishlistForm } from "../wishlist";
import { AddToWishlistForm } from "../wishlist";
import ProductLabels from "../product-labels/ProductLabels";
import wishlistToast from "../toast/wishlistToast";
import useWindowSize from "~/hooks/useWindowSize";

export const StyledBazaarCard = styled(BazaarCard)(() => ({
  height: "100%",
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "10px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 250ms ease-in-out",
  "& .MuiChip-label": {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  "& .content": {
    width: "15px",
    transition: "all 0.3s ease-out",
  },
  "& .discount-controller": {
    marginBottom: "5px",
    paddingRight: "8px",
    paddingLeft: "8px",
  },
  "& .label-controller": { marginBottom: "5px" },
  "& .label-controller-hovered": { marginBottom: "5px" },
  "& .initial": { display: "flex", justifyContent: "center" },
  "& .hovered": { display: "none", whiteSpace: "initial", lineHeight: "10px" },
  "&:hover": {
    "& .controller": { left: 8 },
    "& .cart-controller": { transform: "translateY(-100%)" },
    "& .content": { width: "64px" },
    "& .initial": { display: "none" },
    "& .hovered": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    // "& .brand-controller": { transform: "translateY(100%)" },
    // "& .discount-controller": { transform: "translateY(100%)" },
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  display: "inline-block",
  transition: "all 250ms ease-in-out",
  [theme.breakpoints.down("sm")]: { display: "block" },
  // "&:hover": {
  //   "& .controller": { right: 1 },
  //   "& .cart-controller": { top: 272, opacity: 1 }
  // },
}));

export const LabelWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  position: "absolute",
  top: "8px",
  right: "8px",
  transition: "all 0.3s ease-out",
}));

export const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  padding: "1rem",
  zIndex: 2,
  backgroundColor: "white",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

// ========================================================
type UpointProductCardProps = {
  product: Product;
};
// ========================================================

const UpointProductCard: FC<UpointProductCardProps> = ({ product }) => {
  const { isCustomerLoggedIn } = useRootLoader();

  // const toggleIsFavorite = () => setIsFavorite((fav) => !fav);

  const discount = product.specialPrice
    ? (((product.price - product.specialPrice) / product.price) * 100).toFixed()
    : 0;

  const discountedPrice = discount !== 0 ? product.specialPrice : product.price;

  const blur = product.attributes.find(
    (item) => item.label === "Согтууруулах ундаа"
  );
  const isBlur = blur ? (blur.value === "Yes" ? true : false) : false;

  const width = useWindowSize();
  return (
    <StyledBazaarCard>
      <FlexBox
        sx={{
          justifyContent: { md: "space-between", xs: "center" },
          mt: { xs: 1, md: 2 },
          mb: -2,
          alignItems: "center",
        }}
      >
        {width < 1025 ? (
          <BazaarImage
            src="/assets/images/logos/upointMobile.png"
            sx={{ width: "90px" }}
          />
        ) : (
          <>
            <BazaarImage
              src="/assets/images/logos/upointLogo.png"
              sx={{ height: { md: "17px", xs: "10px" }, ml: 2.25 }}
            />
            <Box
              sx={{
                border: "1px solid #95B3E1",
                borderRadius: "6px 0 0 6px",
                backgroundColor: "#6F41FE",
                fontWeight: 600,
                fontSize: { md: "9px", xs: "7px" },
                color: "white",
                display: "flex",
                justifyContent: "center",
                p: { md: "5px 10px", xs: "5px" },
                textTransform: "uppercase",
              }}
            >
              100% зарцуулаарай
            </Box>
          </>
        )}
      </FlexBox>
      <ImageWrapper>
        <Link to={`${product.url}`} preventScrollReset={true}>
          <BazaarImage
            alt={product.title}
            height="100%"
            width="100%"
            src={product.defaultImage}
            className={isBlur ? "blur" : ""}
            sx={{ px: 4.5, pt: 3.5 }}
          />
        </Link>
      </ImageWrapper>

      <ContentWrapper sx={{ px: 2, pt: 0 }}>
        <FlexBox>
          <Box flex="1 1 0" minWidth="0px" mr={1}>
            <Link to={`${product.url}`}>
              <H3
                mb={1.5}
                title={product.title}
                fontWeight="600"
                className="title"
                color="text.secondary"
                textTransform="uppercase"
                textAlign="center"
                sx={{ fontSize: { md: "14px", xs: "12px" }, pt: 1 }}
              >
                {product.title}
              </H3>
            </Link>
          </Box>
        </FlexBox>
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              border: "1px solid #713FFF",
              borderRadius: "10px 0 0 10px",
              p: { xs: 1, md: 1.5 },
            }}
          >
            <FlexBox
              sx={{
                justifyContent: "center",
                textAlign: "center",
                fontSize: { md: "14px", xs: "12px" },
              }}
            >
              Үнэ
            </FlexBox>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              backgroundColor: "#713FFF",
              display: "flex",
              alignItems: "center",
              borderRadius: "0 10px 10px 0",
            }}
          >
            <FlexBox
              sx={{
                fontWeight: 600,
                fontSize: { md: "16px", xs: "11px" },
                m: "auto",
                color: "white",
              }}
            >
              {formatCurrency(discountedPrice)}
            </FlexBox>
          </Grid>
        </Grid>
      </ContentWrapper>
    </StyledBazaarCard>
  );
};

export default UpointProductCard;
